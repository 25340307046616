<template>
  <v-container class="experiences" id="experience" dark=isDark>
  <h1>Experiences</h1>
      <v-timeline
        dense
      >
        <v-timeline-item
          color="#7EA3CC"
          small
          fill-dot
        >
          <v-row class="pt-1">
            <v-col>
              <v-card flat>
                <v-card-text class="EA-text">
                  <h2 :style="isDark ? { 'color': 'white' } : { 'color': 'black' }"> Electronic Arts</h2>
                  <h4> Software Engineer </h4>
                  <p class="mb-0">
                    September 2022 - Current
                  </p>
                  <p class="department">
                    Development and Release Engineering
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>

        <v-timeline-item
          color="#7EA3CC"
          small
          fill-dot
        >
          <v-row class="pt-1">
            <v-col>
              <v-card flat>
                <v-card-text class="procurify-text">
                  <h2 :style="isDark ? { 'color': 'white' } : { 'color': 'black' }">Procurify</h2>
                  <h4> Software Engineer Co-op </h4>
                  <p class="mb-0">
                    January 2021 - December 2021
                  </p>
                  <p class="department">
                    Cloud Infrastructure and Backend Team
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>

        <v-timeline-item
          color="#7EA3CC"
          small
          fill-dot
        >
          <v-row class="pt-1">
            <v-col>
              <v-card flat>
                <v-card-text class="netgear-text">
                  <h2 :style="isDark ? { 'color': 'white' } : { 'color': 'black' }">Netgear</h2>
                  <h4> Software Test Engineer Co-op</h4>
                  <p class="mb-0">
                    September 2018 - April 2019
                  </p>
                  <p class="department">
                    Mobile Hotspot (Aircard) Team
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>

      </v-timeline>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',
    props: {
      isDark: {
        type: Boolean,
      },
    },
    data: () => ({
    }),
    watch: { // It listens to the change in prop name
      isDark: function () {
        console.log("changing mode"); // print out when the name changes
      },
    },
  }
</script>

<style scoped>
  .experiences{
    height: 100vh;
    width: 1100px;
  }

  @media only screen and (max-width: 956px) {
    .experiences {
      width: 350px;
      height: 125vh;
    }
  }

  .department{
    color: gray;
    font-size: 13px;
  }

  h2{
    font-size: 22px;
    color: black;
  }

  p{
    font-size: 15px;
    color: black;
  }

  div.experience-tabs [role="tab"] {
    justify-content: flex-start;
  }

  .mb-0{
    font-family: 'Courier Prime', monospace;
    color: #FF7568;
    font-size: 13px;
  }

  .EA-text{
    padding-left: 40px;
  }

  .procurify-text{
    padding-left: 40px;
  }

  .netgear-text{
    padding-left: 40px;
  }

</style>