<template>
  <v-container class = "projects" id="project" fluid = "true">
    <h1>Projects</h1>
    <v-row class = "ProjectContent" justify = "center">
      <v-col cols="12" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">GeoLearn</v-list-item-title>
            <v-list-item-subtitle> Web application to test knowledge of flags and country shapes. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> Django, React, NodeJS, Docker, Render </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions class = "githubdemo"  >
          <v-btn color = "dark-grey" href = "https://geolearn-frontend.onrender.com/">demo</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-col>
    <v-col cols="12" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Mastery Race</v-list-item-title>
            <v-list-item-subtitle> League of Legends progress and statistics comparison app. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> React, Express, NodeJS, Vercel </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions class = "githubdemo"  >
          <v-btn color = "dark-grey" href = "https://masteryrace.vercel.app/">demo</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-col>
    <v-col cols="12" class = "description" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Pacman AI</v-list-item-title>
            <v-list-item-subtitle> Artificial Intelligence concepts applied to a Pacman game. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> Python, Searches, Reinforcement Learning</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color = "dark-grey"  href = "https://github.com/YoelYonata/AI-Survey-Class-Projects">Github</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-col>
    <v-col cols="12" class = "description" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Simple Talk</v-list-item-title>
            <v-list-item-subtitle > Simple two way chatting application using Linux terminal. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> C, Linux </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color = "dark-grey"  href = "https://github.com/YoelYonata/S-talk">github</v-btn>
        </v-card-actions>
      </v-card>
      
    </v-col>

      <v-col cols="12" class = "description" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Moody Chat</v-list-item-title>
            <v-list-item-subtitle> Sentiment assessing chat application for Asperger patients. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> Express, NodeJS </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color = "dark-grey"  href = "https://github.com/YoelYonata/MoodyChat">Github</v-btn>
          <v-btn color = "dark-grey"  href = "https://devpost.com/software/moody-chat">Devpost</v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col cols="12" class = "description" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">Library App</v-list-item-title>
            <v-list-item-subtitle> Relational database management system application for library. </v-list-item-subtitle>
            <v-list-item-subtitle class = "tech" id = "tech"> Python, MySQL </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn color = "dark-grey"  href = "https://github.com/YoelYonata/Library-Database-Project">Github</v-btn>
        </v-card-actions>
      </v-card>
      </v-col>
      <v-col cols="12 " class = "description" lg = "4" md ="6" sm = "12">
      <v-card
        class="mx-auto"
        height = "175px"
        outlined
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="headline mb-1">More projects on Github!</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-card>
      </v-col>


    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    name: 'Projects',

    data: () => ({

    }),
  }
</script>

<style scoped>
  
  #pacman{
    padding:15px; 
  }
  .projects{
    min-height: 100vh;
    width: 1200px;
  }
  .githubdemo{
    text-align: right;
  }
  .tech{
    padding-top: 15px;
  }

  #tech{
    color:#ff6f61;
  }

  @media only screen and (max-width: 956px) {
    .projects {
      width: 350px;
    }
  }

</style>