<template>
  <v-app class="app">
    <v-main class="main">
      <v-container class ="headerbar">
          <v-row class = "header">
            <NavBar :isDark="isDarkModeEnabled"/>
            <DarkModeSwitch @switched="onSwitched" :initialState="isDarkModeEnabled" id="darkmodeSwitch"/>
          </v-row>
      </v-container>
      <FaceProfile />
      <Experiences :isDark="isDarkModeEnabled"/>
      <Projects />
      <Contact :isDark="isDarkModeEnabled"/>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import FaceProfile from './components/FaceProfile';
import Experiences from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import DarkModeSwitch from 'vue-dark-mode-switch'
import 'vue-dark-mode-switch/dist/vue-dark-mode-switch.css'

export default {
  name: 'App',

  components: {
    NavBar,
    FaceProfile,
    Experiences,
    Projects,
    Contact,
    DarkModeSwitch,
  },

  methods:{
    onSwitched: function (isSwitched) {
      if (isSwitched === true){
        this.isDarkModeEnabled = true
        this.theme = 'darkMode'
      }else{
        this.isDarkModeEnabled = false
        this.theme = ''
      }
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      document.documentElement.setAttribute('data-theme', this.theme); // updates the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value in local storage
      localStorage.setItem('switch', JSON.stringify(isSwitched));
    },
},
  mounted(){
      let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
      if (localTheme === "darkMode"){
        this.isDarkModeEnabled = true;
        this.$vuetify.theme.dark = true;
      }
      document.documentElement.setAttribute('data-theme', localTheme); // updates the data-theme attribute
  },

  watch: {
    isDarkModeEnabled(newDarkModeState) {
      localStorage.isDarkModeEnabled = newDarkModeState;
    }
  },

  data() {
    return{
      isDarkModeEnabled: JSON.parse(localStorage.getItem('switch')),
      theme: localStorage.getItem('theme')
    }
  }

};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
  :root {
      /* Variables for default theme (light-mode) */
      --background-color: #FFFFFF;
      --text-color: black;  
  }
  [data-theme="darkMode"] {
    /* Variables for dark mode */
    --background-color: #1e1e1e;
    --text-color: white;   
  }
  .main{
    background-color: var(--background-color);
    color: var(--text-color);
  }
  .headerbar{
    padding: 0px;
  }
  #darkmodeSwitch{
    top: 0px;
    right: 0px;
    position: absolute;
    padding: 10px;
  }
  @media (max-width: 380px) {
    #darkmodeSwitch{
      top: 30px;
    }
  }
</style>