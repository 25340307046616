<template>  
  <v-container class="faceprofile">    
    <v-row class="PhotoAndDescription">
      <v-col cols="10" sm = "9" md ="5" lg= "5" class="photo">
        <v-avatar size = "280">
          <img
            src='../assets/yoel_Circle.jpg'
            alt="myPhoto"
            class = "profilePic"
          >
        </v-avatar>
      </v-col>

      <v-col cols="9" sm = "8" md ="5" lg = "4" class="profile">
        <typewriter class="typewriter" :type-interval="75">
          <h1 id = "myname">
            Hello! I'm Yoel 👋
          </h1>
        </typewriter>

        <p id="bio1">
          My name is Yoel Yonata. I am a software engineer with a passion for innovation and an innate sense of curiosity. 
          I am interested in Cloud Infrastructure, Security and all things DevOps related.
        </p>

        <p id="bio2">
          I have recently graduated from Simon Fraser University with a Bachelor's in Engineering Science and
          I am now working at Electronic Arts (EA) as a part of their Development and Release Engineering team. 
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Typewriter from "typewriter-vue";
  export default {
    name: 'FaceProfile',
                                                                                                                                 
    components: {
      Typewriter,
    },
    data: () => ({
    }),
  }
</script>

<style scoped>
  #myname{
    font-size: 40px;
  }
  .faceprofile{
    height: 100vh;
  }

  @media (max-width: 930px) {
    .faceprofile{
      height: 150vh;
    }
  }

  .PhotoAndDescription{
    position: absolute; 
    top: 15%;
    transform: translateY(-50%);
  }
  
  .photo{
    text-align: right;
  }

  .profilePic{
    border-radius: 50%;
  }

  .profile{
    text-align: left;
    margin-left:70px;
    font-family: 'Courier Prime', monospace;
  }

  #bio1{
      -webkit-animation: 3s ease 0s normal forwards 1 fadein;
      animation: 3s ease 0s normal forwards 1 fadein;
  }
  #bio2{
      -webkit-animation: 5s ease 0s normal forwards 1 fadein;
      animation: 5s ease 0s normal forwards 1 fadein;
  }

  @keyframes fadein{
      0% { opacity:0; }
      66% { opacity:0; }
      100% { opacity:1; }
  }

  @-webkit-keyframes fadein{
      0% { opacity:0; }
      66% { opacity:0; }
      100% { opacity:1; }
  }

</style>