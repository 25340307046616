import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
  duration: 600,
  updateHistory: false,
})

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
