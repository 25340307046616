<template>
<v-container class = "icons" id="mycontact">
  <h1> Let's Connect!</h1>
  <v-row align="center" justify="center">
    <v-col>
      <v-btn icon x-large color="#0072b1" href = "https://www.linkedin.com/in/yoel-yonata/">
        <v-icon>fab fa-linkedin</v-icon>
      </v-btn>
      <v-btn icon x-large :color="isDark ? 'white' : '#171515' " href = "https://github.com/YoelYonata">
        <v-icon>fab fa-github-square</v-icon>
      </v-btn>
      <v-btn icon x-large color="#d6a774" href = "mailto:yoel.yonata@gmail.com" >
        <v-icon>fas fa-envelope</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
  export default {
    name: 'Contact',
    props: {
      isDark: {
        type: Boolean,
      },
    },
    data: () => ({
    }),
    watch: { // It listens to the change in prop name
      isDark: function () {
        console.log("change mode"); // print out when the name changes
      },
    }
  }
</script>

<style scoped>
  .icons{
    margin:auto;
    margin-bottom: 150px;
    text-align: center;
  }

</style>