<template>
  <v-tabs hide-slider color="#7EA3CC" class="navbar">
    <v-tab><a href="#experience" v-smooth-scroll>Experiences</a></v-tab>
    <v-tab><a href="#project" v-smooth-scroll>Projects</a></v-tab>
    <v-tab><a href="#mycontact" v-smooth-scroll>Contact</a></v-tab>
  </v-tabs>
</template>

<script>
  export default {
    name: 'NavBar',

    data: () => ({
      
    }),
  }
</script>

<style scoped>
  .navbar {
    padding-left: 10px;
    padding-top: 10px;
  }

  a:link {
    color: #7EA3CC;
    text-decoration: none;
  }

  a:visited {
    color: #7EA3CC;
    text-decoration: none;
  }

  a:hover {
    color: #7EA3CC;
    text-decoration: none;
  }

  a:active {
    color: #7EA3CC;
    text-decoration: none;
  }
</style>